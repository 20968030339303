@mixin lreset {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin reset {
  margin: 0;
  padding: 0;
}

@mixin round-img {
  width: 100%;
  height: 100%;
  display: block;

  object-fit: cover;
  object-position: center;

  border-radius: 50%;
}

@mixin contain-img {
  width: 100%;
  height: 100%;
  display: block;

  object-fit: contain;
  object-position: center;
}
