@import "../../../public/scss/variables/color";
@import "../../../public/scss/variables/media";
@import "../../../public/scss/variables/fonts";

@import "../../../public/scss/mixins/media";
@import "../../../public/scss/mixins/fonts";
@import "../../../public/scss/mixins/diff";

.Preloader {
  width: 40px;
  height: 40px;
  position: relative;
  &Background {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.85);
    &.Rounded {
      border-radius: 0.75em;
      @include media-desktop-1440 {
        border-radius: 1.5em;
      }
    }
  }
  &Wrapper {
    &First {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 9;
    }
    &Second {
      width: 100%;
      height: 100%;
      position: relative;
    }
    &Third {
      width: 100%;
      height: 100%;
      max-height: 100vh;
      position: sticky;
      top: 0;
    }
  }
  &Dot {
    width: 6px;
    height: 6px;
    position: absolute;
    background: $grey-c0;
    border-radius: 50%;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
    animation: preloader 1s infinite;
    opacity: 0;
    z-index: 1;
    &:nth-child(1) {
      left: 17px;
      top: 0;
    }
    &:nth-child(2) {
      left: 29px;
      top: 5px;
      animation-delay: 0.125s;
    }
    &:nth-child(3) {
      top: 17px;
      right: 0;
      animation-delay: 0.25s;
    }
    &:nth-child(4) {
      left: 29px;
      top: 29px;
      animation-delay: 0.375s;
    }
    &:nth-child(5) {
      left: 17px;
      bottom: 0;
      animation-delay: 0.5s;
    }
    &:nth-child(6) {
      left: 5px;
      top: 29px;
      animation-delay: 0.625s;
    }
    &:nth-child(7) {
      left: 0;
      top: 17px;
      animation-delay: 0.75s;
    }
    &:nth-child(8) {
      left: 5px;
      top: 5px;
      animation-delay: 0.875s;
    }
  }
}
@keyframes preloader {
  20% {
    transform: scale(1.3);
    opacity: 1;
  }
}
