@font-face {
  font-family: "Golos";
  font-weight: 400;
  font-style: normal;
  src: url("/public/fonts/golos/GolosText-Regular.woff2") format("woff2"),
    url("/public/fonts/golos/GolosText-Regular.woff") format("woff");
}
@font-face {
  font-family: "Golos";
  font-weight: 500;
  font-style: normal;
  src: url("/public/fonts/golos/GolosText-Medium.woff2") format("woff2"),
    url("/public/fonts/golos/GolosText-Medium.woff") format("woff");
}
@font-face {
  font-family: "Golos";
  font-weight: 600;
  font-style: normal;
  src: url("/public/fonts/golos/GolosText-DemiBold.woff2") format("woff2"),
    url("/public/fonts/golos/GolosText-DemiBold.woff") format("woff");
}
@font-face {
  font-family: "Golos";
  font-weight: 700;
  font-style: normal;
  src: url("/public/fonts/golos/GolosText-Bold.woff2") format("woff2"),
    url("/public/fonts/golos/GolosText-Bold.woff") format("woff");
}
@font-face {
  font-family: "Golos";
  font-style: normal;
  font-weight: 800;
  src: url("/public/fonts/golos/GolosText-Black.woff2") format("woff2"),
    url("/public/fonts/golos/GolosText-Black.woff") format("woff");
}
